import React, { useEffect } from "react";

import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import ContactUs from '../components/ContactUs/ContactUs';

import mobileBackground from '../../utils/assets/shared/mobile/backgroud.png';
import tabletBackground from '../../utils/assets/shared/tablet/backgroud.png';
import desktopBackground from '../../utils/assets/shared/desktop/backgroud.png';




const Contact = props => {

    useEffect(()=>{
        window.scroll(0,0);
     },[])

    const bannerSectionInfo =
    {
       cardTitle: "Better health for you 24/7",
       cardBodyText: `We provide specialized CARE whenever you need it with our national network of medical professionals and advanced technology application.`,
       cardButtonText: "",
       lengendBackground: desktopBackground,
       mobileImg :  mobileBackground,
       tabletImg  : tabletBackground
    }

    return (
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo}/>
            <NavegationInfo page="Contact"/>
            <ContactUs />

        </React.Fragment>
    );
};

export default Contact;