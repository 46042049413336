import React from "react";

import './BenefitsList.css';


import urgentCareIcon from '../../../../../utils/assets/icons/urgent-carev2.png';
import primaryCareIcon from '../../../../../utils/assets/icons/primary-carev2.png';
import mentalCareIcon from '../../../../../utils/assets/icons/mental-healthv2.png';
import careNavigationIcon from '../../../../../utils/assets/icons/care-navegationv2.png';
import dermatologyCareIcon from '../../../../../utils/assets/icons/dermatologyv2.png';
import physicalTherapyIcon from '../../../../../utils/assets/icons/physical-therapyv2.png';
const BenefitsList = props => {


    return (
        <div className="BenefitsList__main-container">
            <div className="BenefitsList__list-container">
                <div className="BenefitsList__first-benefits">
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={urgentCareIcon} alt="urgentCareIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Urgent Care</h6>
                            <p className="BenefitsList__description">
                            Reduce ER visits. Now you can get a 
                            quick and efficient diagnosis of your
                            health status in the moment you need it.
                            </p>
                        </div>
                    </div>

                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={primaryCareIcon} alt="primaryCareIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Primary Care</h6>
                            <p className="BenefitsList__description">
                            Choose a primary care physician to 
                            manage your medical treatments, 
                            medications, and preventive care.
                            </p>
                        </div>     
                    </div>
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={mentalCareIcon} alt="mentalCareIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Mental Health</h6>
                            <p className="BenefitsList__description">
                            Without leaving your home, you can 
                            access a wide variety of therapists with
                            same-day consultations.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="BenefitsList__second-benefits">
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={careNavigationIcon} alt="careNavigationIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Care Navigation</h6>
                            <p className="BenefitsList__description">
                            We can help you find providers, get
                            second opinions, learn to take
                            medications, and answer questions.
                            </p>
                        </div>
                    </div>
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={dermatologyCareIcon} alt="dermatologyCareIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Dermatology</h6>
                            <p className="BenefitsList__description">

                            Treat the most common skin, nail, and
                            hair conditions right away and start a
                            healthy routine.
                            </p>
                        </div>
                    </div>
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={physicalTherapyIcon} alt="physicalTherapyIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Physical Therapists</h6>
                            <p className="BenefitsList__description">
                            To help you prevent injury and reduce 
                            pain, we offer personalized care plans
                            with simple steps.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BenefitsList;