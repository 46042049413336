import React, { useEffect } from 'react';
import checkoutBannerImg from '../../utils/assets/checkout/checkout.png';
import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import PaymentInfo from '../components/PaymentInfo/PaymentInfo';

import mobileBackground from '../../utils/assets/checkout/mobile/check-out.png';
import tabletBackground from '../../utils/assets/checkout/tablet/check-out.png';

const Checkout = () => {

    useEffect(()=>{
        window.scroll(0,0);
    },[])
    const bannerSectionInfo =
    {
       cardTitle: "Thank you for joining our community",
       cardBodyText: `Now you have access to an exclusive family care plan with 24/7 medical consultations, prescription drugs, personalized treatments and more.`,
       cardButtonText: "",
       lengendBackground: checkoutBannerImg,
       mobileImg : mobileBackground,
       tabletImg : tabletBackground
    }

    return (
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="Checkout"/>
           <PaymentInfo/>
        </React.Fragment>
    );
};

export default Checkout;
