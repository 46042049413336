import React, { useState } from "react";

import './Faq.css';

import Accordion from './components/Accordion/Accordion';
import ContactCard from '../../../shared/components/ContactCard/ContactCard';




const Faq = props => {

    const faqsInformation =
        [
            {
                question: "How do I log in to my account?",
                answer:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
                open: false
            },
            {
                question: "What kind of treatments do dermatologists provide?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question:
                    "What does the Basic Plan cover?",
                answer: "This many!",
                open: false
            },
            {
                question: "What mental health problems do therapists treat?",
                answer:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
                open: false
            },
            {
                question: "What does the Premium Plan cover?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question:
                    "What problems do physiotherapists treat?",
                answer: "This many!",
                open: false
            },
            {
                question: "What our service does not include?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question: "What to do in an emergency?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question: "Where can I have access to the services?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question: "What is Rx Benefits?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question: " How do I schedule a medical consultation?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question: "What is Lyric?",
                answer: "You! The viewer!",
                open: false
            }
            
        ]

    const [faqs, setFaqs] = useState(faqsInformation)

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
  
        <section className="Faq__main-container">
            {/* <h1 className="Faq__main-container__main-title">FAQ</h1> */}
            {/* <div className="Faq__faqs-container">   
                <div className="Faq__faqs columns faqs">
                    {faqs.map((faq, index) => (
                        <Accordion faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}></Accordion>
                    ))}
                </div>
            </div> */}
            <div className="Faq__contact-card-component-container">
                <ContactCard/>
            </div>
        </section>

    );
};

export default Faq;