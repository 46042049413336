import React, { useEffect } from "react";

import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import Cancelation from '../components/Cancelation/Cancelation';

import mobileBackground from '../../utils/assets/shared/mobile/backgroud.png';
import tabletBackground from '../../utils/assets/shared/tablet/backgroud.png';
import desktopBackground from '../../utils/assets/shared/desktop/backgroud.png';



 

const CancelService = props => {
    useEffect(()=>{
        window.scroll(0,0);
     },[])
     const legendInfo =
     {
         cardTitle: "Want to unsubscribe?",
         cardBodyText: `Cancel the service quickly and easily. By selecting your cancellation reason, we hope you will help us improve our service.`,
         cardButtonText: "",
         lengendBackground: desktopBackground,
         mobileImg : mobileBackground,
         tabletImg : tabletBackground
     }

    return (
        <React.Fragment>
            <WelcomeSection props={legendInfo}/>
            <NavegationInfo page="Cancel service"/>
            <Cancelation/>
            
            
        </React.Fragment>
    );
};

export default CancelService;