import React, { useState } from "react";

import './AvailablePlan.css';

import SubscriptionCard from '../../../shared/components/SubscriptionCard/SubscriptionCard';
import ContactCard from '../../../shared/components/ContactCard/ContactCard';

const AvailablePlan = props => {

    const subscriptionCardInformation = [
        {
            subscriptionType : "Basic",
            subscriptionPrice: "39.95",
            subscriptionTrialInfo : "Trial for 30 days at",
            subscriptionTrialPrice: "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        },
        {
            subscriptionType : "Premium",
            subscriptionPrice: "49.95",
            subscriptionTrialInfo : "Trial for 30 days at",
            subscriptionTrialPrice: "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }
    ]
    const [subscriptionCards, setSubscriptionCards] = useState(subscriptionCardInformation);
    return (
        <section className="AvailablePlan__main-section-container">
            <div className="AvailablePlan__title-container">
                <h1 className="AvailablePlan__title">Health care plans available to you</h1>
            </div>
            <div className="AvailablePlan__components-container">
                <div className="AvailablePlan__subscriptionCard">
                    {
                        subscriptionCards.map((subscriptionCard, index) => (
                            <SubscriptionCard key={index} props={subscriptionCard} />
                        ))
                    }
                </div>
                <div className="AvailablePlan__contact-card-container">
                    <ContactCard />
                </div>
            </div>
        </section>
    );
};

export default AvailablePlan;