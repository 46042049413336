import React, { useEffect } from "react";

import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';
import Benefits from "../components/Benefits/Benefits";
import Faq from '../components/Faq/Faq';

import aboutUsBannerImg from '../../utils/assets/aboutUs/know-about-us.png';
import vitualCallImg from '../../utils/assets/aboutUs/virtual-call.png';

import mobileImg from '../../utils/assets/aboutUs/mobile/know-about.png';
import tabletImg from '../../utils/assets/aboutUs/tablet/know-about-us.png';

import virtualCallMobileImg from '../../utils/assets/aboutUs/mobile/virual-call.png';
import virtualCallTabletImg from '../../utils/assets/aboutUs/tablet/virual-call.png';


const AboutUs = props => {
   useEffect(()=>{
      window.scroll(0,0);
   },[])
    const bannerSectionInfo =    
     {
        cardTitle: "Find out more about us",
        cardBodyText: `Learn about the benefits and meet our team of professionals. Become a part of our community of members who are enjoying our world-class medical care services.`,
        cardButtonText: "Join us",
        lengendBackground: aboutUsBannerImg,
        mobileImg : mobileImg,
        tabletImg : tabletImg
     }

     const productDescription = {
        title : "How we are",
        subtitle : "Doc Chat LLC is an authorized reseller of the services of GetLyric, Inc.",
        paragraphs : ['The company provides services related to online medical consultations through video calls, provides storage of electronic medical records and many other services.',
                    "To access your membership login or go to www.getlyric.com and enter your Member ID. Make your family's healthcare experience smarter and more enjoyable.",
                    "Downloading our app, available for Apple or Android. Communicate with a doctor, ask quick questions, or manage your medications with our AI-powered mobile app."
                    ],
        instructions : [],
        img : vitualCallImg,
        mobileImg : virtualCallMobileImg,
        tabletImg : virtualCallTabletImg

     }
          
    return(
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="About us"/>
           <ProductDescription props={productDescription} />
           <SectionInfo title = "Benefits" color="#028547"/>
           <Benefits/>
           <Faq/>
        </React.Fragment>
    );
};

export default AboutUs;