import React, { useEffect } from "react";

import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import MembershipTerms from '../components/MembershipTerms/MembershipTerms';


import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";

import mobileBackground from '../../utils/assets/shared/mobile/backgroud.png';
import tabletBackground from '../../utils/assets/shared/tablet/backgroud.png';
import desktopBackground from '../../utils/assets/shared/desktop/backgroud.png';

const TermsOfService = props => {

    useEffect(()=>{
        window.scroll(0,0);
     },[])
    const bannerSectionInfo =
     {
        cardTitle: "Terms and Conditions for using this Service",
        cardButtonText: "Try Now",
        lengendBackground: desktopBackground,
        mobileImg : mobileBackground,
        tabletImg : tabletBackground
     }

    return (
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="Terms of service"/>
            <MembershipTerms/>
        </React.Fragment>
    );
};

export default TermsOfService;