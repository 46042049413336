import React, { useEffect } from "react";
import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";

import teamOfDoctorFirstBannner from '../../utils/assets/home/team-of-doctors-banner.jpg';
import doctorPointerSecondBanner from '../../utils/assets/home/doctor-pointer-right.png';
import SectionDivider from "../components/SectionDivider/SectionDivider";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import doctorWorkingImg from '../../utils/assets/home/doctor-working-second-banner.jpg';


import doctorIcon from '../../utils/assets/icons/doctor.png';
import callCenterIcon from '../../utils/assets/icons/call-center.png';
import medicalConsultingIcon from '../../utils/assets/icons/doctor-medical-consulting.png';
import doctorToolsIcon from '../../utils/assets/icons/doctor-tools.png';
import medicalContractIcon from '../../utils/assets/icons/contract.png';
import computerResultIcon from '../../utils/assets/icons/computer-results.png';


import careIcon from '../../utils/assets/icons/care-icon.png';
import urgentCareIcon from '../../utils/assets/icons/urgent-care.png';
import primaryCareIcon from '../../utils/assets/icons/primary-care.png';
import mentalHealthCareIcon from '../../utils/assets/icons/mental-health.png';
import dermatologyCareIcon from '../../utils/assets/icons/dermatology-care.png';
import physicalCareIcon from '../../utils/assets/icons/physical-care.png';
import ProductDescription from "../../shared/components/ProductDescription/ProductDescription";
import JoinUs from "../components/JoinUs/JoinUs";
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import SubscriptionPlan from "../components/SubscriptionPlan/SubscriptionPlan";

import teamOfDoctor from '../../utils/assets/home/mobile/team-of-doctors.png';
import doctorWorkingMobileImg from '../../utils/assets/home/mobile/doctor-working-whith-a-computer.png';
import doctorPointerMobileImg from '../../utils/assets/home/mobile/doctor-pointing-right.png';

import teamOfDoctorTabletImg from '../../utils/assets/home/tablet/team-of-doctors.png';
import doctorWorkingTabletImg from '../../utils/assets/home/tablet/doctor-working-whith-a-computer.png';
import doctorPointerTabletImg from '../../utils/assets/home/tablet/doctor-pointing-right.png';





const Home = props => {

    useEffect(()=>{
        window.scroll(0,0);
     },[]);
     
     const bannerSectionInfo = [
         {
             cardTitle : "Access to a medical professional when you need it the most",
             cardButtonText : "start now",
             lengendBackground : teamOfDoctorFirstBannner,
             mobileImg : teamOfDoctor,
             tabletImg : teamOfDoctorTabletImg
         },
         {
            cardTitle : "Always make your family's health a priority",
            cardButtonText : "join now",
            lengendBackground :doctorPointerSecondBanner ,
            mobileImg : doctorPointerMobileImg,
            tabletImg : doctorPointerTabletImg,
            cardBannerPosition : 2
        }
     ]

     const dividerInfo = [
        {
            sectionName : "BENEFITS",
            icon1 : doctorIcon,
            description1 : "Specialty Referral",
            icon2: callCenterIcon,
            description2 : "24 / 7 / 365 assistance",
            icon3 : medicalConsultingIcon,
            description3 : "Free medical counseling",
            icon4 : doctorToolsIcon,
            description4 : "AI-powered mobile app",
            icon5 : medicalContractIcon,
            description5:"medical prescriptions",
            icon6 : computerResultIcon,
            description6 : "virtual consultation"

        },
        {
            sectionName : "SPECIALITY AREAS",
            icon1 : careIcon,
            description1 : "care navigation",
            icon2: urgentCareIcon,
            description2 : "urgent care",
            icon3 : primaryCareIcon,
            description3 : "primary care",
            icon4 : mentalHealthCareIcon,
            description4 : "mental health",
            icon5 : dermatologyCareIcon,
            description5:"dermatology",
            icon6 : physicalCareIcon,
            description6 : "physical therapists"

        }
     ]

     const productDescription = {
        title : "How it works?",
        img : doctorWorkingImg,
        mobileImg: doctorWorkingMobileImg,
        tabletImg : doctorWorkingTabletImg

     }

    return(     
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo[0]}/>
            <SectionDivider props={dividerInfo[0]}/>
            {/* <HowItWorks /> */}
            <ProductDescription props={productDescription}/>
            <SectionDivider props={dividerInfo[1]}/>
            {/* <JoinUs/> */}
            <WelcomeSection props={bannerSectionInfo[1]}/>
            <SectionInfo title={"Get a 30-day trial version for ONLY $3.29"}/>
            <SubscriptionPlan/>
        </React.Fragment>
    );
}; 

export default Home;