import React, {useEffect} from "react";


import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import Refund from "../components/Refund/Refund";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";


import mobileBackground from '../../utils/assets/shared/mobile/backgroud.png';
import tabletBackground from '../../utils/assets/shared/tablet/backgroud.png';
import desktopBackground from '../../utils/assets/shared/desktop/backgroud.png';



const RefundPolicy = props => {
    const bannerSectionInfo =
    {
        cardTitle: "Not satisfied with your experience?",
        cardButtonText: "",
        lengendBackground: desktopBackground,
        mobileImg : mobileBackground,
        tabletImg : tabletBackground
    }
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    return (
        <React.Fragment>
         
            <WelcomeSection props={bannerSectionInfo}/>
            <NavegationInfo page="Refund policy"/>
            <Refund />
       
            
           
            
        </React.Fragment>
    );
};

export default RefundPolicy;