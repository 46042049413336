import React, { useEffect } from "react";

import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import PrivacyTerms from "../components/PrivacyTerms/PrivacyTerms";
import mobileBackground from '../../utils/assets/shared/mobile/backgroud.png';
import tabletBackground from '../../utils/assets/shared/tablet/backgroud.png';
import desktopBackground from '../../utils/assets/shared/desktop/backgroud.png';


const PrivacyPolicy = props => {
    
    useEffect(()=>{
        window.scroll(0,0);
     },[])
    const bannerSectionInfo =
     {
        cardTitle: "How the information we gather is used",
        cardButtonText: "Try Now",
        lengendBackground: desktopBackground,
        mobileImg : mobileBackground,
        tabletImg : tabletBackground,
     }

    return (
        <React.Fragment>
        <WelcomeSection props={bannerSectionInfo}/>
        <NavegationInfo page="Privacy policy"/>
        <PrivacyTerms/>

        </React.Fragment>
    );
};

export default PrivacyPolicy;