import React, {useState} from "react";
import './SubscriptionPlan.css';

import ContactCard from '../../../shared/components/ContactCard/ContactCard';
import SubscriptionCard from '../../../shared/components/SubscriptionCard/SubscriptionCard';


const SubscriptionPlan = props => {

    const subscriptionCardInformation = [
        {
            subscriptionType : "Basic",
            subscriptionPrice: "39.95",
            subscriptionTrialInfo : "Trial for 30 days at",
            subscriptionTrialPrice: "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        },
        {
            subscriptionType : "Premium",
            subscriptionPrice: "49.95",
            subscriptionTrialInfo : "Trial for 30 days at",
            subscriptionTrialPrice: "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }
        ]

    const [subscriptionCards, setSubscriptionCard ] = useState(subscriptionCardInformation);
    return(
       <section className="SubscriptionPlan__main-section-container">
            <div className="SubscriptionPlan__title-container">
                <h1 className="SubscriptionPlan__title">Health care plans available to you</h1>
            </div>
            <div className="SubscriptionPlan__subscription-plans-container">
                    {
                        subscriptionCards.map((subscriptionCard, index) => (
                            <SubscriptionCard key={index} props={ subscriptionCard } />
                        ))
                    }
            </div>
            <div className="SubscriptionPlan__customer-service-container">
                <ContactCard/>
            </div>
       </section>
    );
};

export default SubscriptionPlan;