import React from "react";


import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import Error404 from "../components/Error404/Error404";

import mobileBackgroundImg from '../../utils/assets/shared/mobile/backgroud.png';
import tabletBackgroundImg from '../../utils/assets/shared/tablet/backgroud.png';
import desktopBackgroundImg from '../../utils/assets/shared/desktop/backgroud.png';

const NotFound = () =>{

    const bannerWelcomeSection =
    {
       cardTitle: "Nothing to worry about",
       cardButtonText: "",
       lengendBackground: desktopBackgroundImg,
       mobileImg : mobileBackgroundImg,
       tabletImg : tabletBackgroundImg
    }

    return (
        <React.Fragment>
            <WelcomeSection props={bannerWelcomeSection}/>
            <Error404/>

        </React.Fragment>
    );
}

export default NotFound;