import React, { useEffect } from "react";


import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';


import ourCarePlansBannerImg from '../../utils/assets/price/check-our-care-plans.png';
import AvailablePlan from "../components/AvailablePlan/AvailablePlan";

import ourCarePlansMobileImg from '../../utils/assets/price/mobile/check-our-care-plans.png';
import ourCarePlansTabletImg from '../../utils/assets/price/tablet/check-our-care-plans.png';




const Price = props => {
    
    useEffect(()=>{
        window.scroll(0,0);
     },[]);

     const bannerSectionInfo =
     {
        cardTitle: "Access to a nationwide network of licensed doctors.",
        cardButtonText: "Try Now",
        lengendBackground: ourCarePlansBannerImg,
        mobileImg : ourCarePlansMobileImg,
        tabletImg : ourCarePlansTabletImg
     }

    return (
        <React.Fragment>
             <WelcomeSection props={bannerSectionInfo}/>
             <NavegationInfo page="price"/>
             <SectionInfo title={"Get a 30-day trial version for ONLY $3.29"} />
             <AvailablePlan/>
        </React.Fragment>
    );
};

export default Price;