import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';
import './WelcomeSection.css';



const WelcomeSection = ({ props }) => {

    const [componentToShow, setComponentToShow] = useState(window.innerWidth <= 767.98 ? 'mobile' : window.innerWidth >= 768 && window.innerWidth <=991.98 ? 'tablet' : 'main');

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            console.log(windowWidth)
            if (windowWidth <= 767.98) {
                setComponentToShow('mobile');
            } else if (windowWidth >= 768 && windowWidth <= 991.98) {
                setComponentToShow('tablet');
            } 
            else{
                setComponentToShow('main');
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const renderComponent = () => {
        switch (componentToShow) {
            case 'mobile':
                return (
                    <section className="WelcomeSection__mobile-section-container">
                        <Mobile props={props}/>
                    </section>
                );
            case 'tablet':
                return (
                    <section className="WelcomeSection__tablet-section-container">
                      <Tablet props={props}/>
                    </section>
                );
            case 'main':
            default:
                return (
                    <Desktop props={props}/>
                );
        }
    };

    return renderComponent()


};




const Mobile = ({ props }) => {
    
    let bannerCardInformation;
    switch (window.location.pathname) {


        case '/':
            if( props.cardBannerPosition === 2){
                bannerCardInformation = (
                    <>
                    
                    <div className="WelcomeSection-mobile__card-info-component-container" style={{order : 1}}>
                        <CardInfo props={props}></CardInfo>
                    </div>
                    <div className="WelcomeSection-mobile__img-container" style={{order: 2}}>
        
                        <img src={props.mobileImg} alt="banner img" className="WelcomeSection-mobile__img" />
                       
                    </div>
                    </>
                )
            }else{
                bannerCardInformation = (
                    <>
                    <div className="WelcomeSection-mobile__img-container">
        
                        <img src={props.mobileImg} alt="banner img" className="WelcomeSection-mobile__img" />
                       
                    </div>
                    <div className="WelcomeSection-mobile__card-info-component-container">
                        <CardInfo props={props}></CardInfo>
                    </div>
                    </>
                ) 
            }
        break;
        case '/checkout':
        case '/about-us':
        case '/price':
        bannerCardInformation = (
            <>
            <div className="WelcomeSection-mobile__img-container">

                <img src={props.mobileImg} alt="banner img" className="WelcomeSection-mobile__img" />
               
            </div>
            <div className="WelcomeSection-mobile__card-info-component-container">
                <CardInfo props={props}></CardInfo>
            </div>
            </>
        )
            break;

        

        case '/contact-us':
            bannerCardInformation = (
                <div className="WelcomeSection-mobile__img-container" style={{ backgroundImage: `url(${props.mobileImg})`}}>
                    
                    <h1 className="WelcomeSection-mobile__join-title">Better health for you 24/7</h1>
                    
                </div>
            )

            break;
        
        case '/cancel-service':
            bannerCardInformation = (
                <div className="WelcomeSection-mobile__img-container" style={{ backgroundImage: `url(${props.mobileImg})`}}>
                    <h1 className="WelcomeSection-mobile__join-title"> Want to unsubscribe?</h1>
                </div>
            )
            break;

        case '/refund-policy':
            bannerCardInformation = (
                <div className="WelcomeSection-mobile__img-container" style={{ backgroundImage: `url(${props.mobileImg})`}}>
                    <h1 className="WelcomeSection-mobile__join-title">Not satisfied with your experience?</h1>
                </div>
            )
            break;

        case '/terms-of-service':
            bannerCardInformation = (
                <div className="WelcomeSection-mobile__img-container" style={{ backgroundImage: `url(${props.mobileImg})`}}>
                    <h1 className="WelcomeSection-mobile__join-title"> Terms for using this Service</h1>
                </div>
            )
            break;

        case '/privacy-policy':
            bannerCardInformation = (
                <div className="WelcomeSection-mobile__img-container" style={{ backgroundImage: `url(${props.mobileImg})`}}>
                    <h1 className="WelcomeSection-mobile__join-title"> How the information we gather is used</h1>
                </div>
            )
            break;

           

        default:
            bannerCardInformation = (
                <div className="WelcomeSection-mobile__img-container" style={{ backgroundImage: `url(${props.mobileImg})`}}>
                    <h1 className="WelcomeSection-mobile__join-title">Something went wrong!</h1>
                </div>
            )
            break;

    }


  return (
    <section className="WelcomeSection-mobile__main-section-container">
        {bannerCardInformation}
    </section>
  );
}

const Tablet = ({props})=>{
    let bannerCardInformation;
    switch (window.location.pathname) {


        case '/':
        case '/checkout':
        case '/about-us':
        case '/price':

        bannerCardInformation = (
            <>
            <div className="WelcomeSection-tablet__img-container">
                <img src={props.tabletImg} alt="banner img" className="WelcomeSection-tablet__img" />
            </div>
            <div className="WelcomeSection-tablet__card-info-component-container">
                <CardInfo props={props}></CardInfo>
            </div>
            </>
        )
            break;

        

        case '/contact-us':
            bannerCardInformation = (
                <div className="WelcomeSection-tablet__img-container" style={{ backgroundImage: `url(${props.tabletImg})`}}>
                    <h1 className="WelcomeSection-tablet__join-title">Better health for you 24/7</h1>
                </div>
            )

            break;
        
        case '/cancel-service':
            bannerCardInformation = (
                <div className="WelcomeSection-tablet__img-container" style={{ backgroundImage: `url(${props.tabletImg})`}}>
                    <h1 className="WelcomeSection-tablet__join-title"> Want to unsubscribe?</h1>
                </div>
            )
            break;

        case '/refund-policy':
            bannerCardInformation = (
                <div className="WelcomeSection-tablet__img-container" style={{ backgroundImage: `url(${props.tabletImg})`}}>
                    <h1 className="WelcomeSection-tablet__join-title">Not satisfied with your experience?</h1>
                </div>
            )
            break;

        case '/terms-of-service':
            bannerCardInformation = (
                <div className="WelcomeSection-tablet__img-container" style={{ backgroundImage: `url(${props.tabletImg})`}}>
                    <h1 className="WelcomeSection-tablet__join-title">Terms for using this Service</h1>
                </div>
            )
            break;

        case '/privacy-policy':
            bannerCardInformation = (
                <div className="WelcomeSection-tablet__img-container" style={{ backgroundImage: `url(${props.tabletImg})`}}>
                    <h1 className="WelcomeSection-tablet__join-title">How the information we gather is used </h1>
                </div>
            )
            break;

        

        default:
            bannerCardInformation = (
                <div className="WelcomeSection-tablet__img-container" style={{ backgroundImage: `url(${props.tabletImg})`}}>
                    <h1 className="WelcomeSection-tablet__join-title">Something went wrong!</h1>
                </div>
            )
            break;

    }


  return (
    <section className="WelcomeSection-tablet__main-section-container">
        {bannerCardInformation}
    </section>
  );
}

const Desktop = ({ props }) => {
    
    let bannerCardInformation;
    switch (window.location.pathname) {


        case '/':
        case '/checkout':
        case '/about-us':
        case '/price':

        bannerCardInformation = (
           
            <div className="WelcomeSection__main-section-container" style={{ backgroundImage: `url(${props.lengendBackground})`}}>
                <CardInfo props={props}></CardInfo>
            </div>
           
        )
            break;

        

        case '/contact-us':
            bannerCardInformation = (
                <div className="WelcomeSection-desktop__img-container" style={{ backgroundImage: `url(${props.lengendBackground})`}}>
                    <h1 className="WelcomeSection-desktop__join-title">Better health for you 24/7</h1>
                </div>
            )

            break;
        
        case '/cancel-service':
            bannerCardInformation = (
                <div className="WelcomeSection-desktop__img-container" style={{ backgroundImage: `url(${props.lengendBackground})`}}>
                    <h1 className="WelcomeSection-desktop__join-title"> Want to unsubscribe?</h1>
                </div>
            )
            break;

        case '/refund-policy':
            bannerCardInformation = (
                <div className="WelcomeSection-desktop__img-container" style={{ backgroundImage: `url(${props.lengendBackground})`}}>
                    <h1 className="WelcomeSection-desktop__join-title"> Problems with an item?</h1>
                </div>
            )
            break;

        case '/terms-of-service':
            bannerCardInformation = (
                <div className="WelcomeSection-desktop__img-container" style={{ backgroundImage: `url(${props.lengendBackground})`}}>
                    <h1 className="WelcomeSection-desktop__join-title"> Service terms</h1>
                </div>
            )
            break;

        case '/privacy-policy':
            bannerCardInformation = (
                <div className="WelcomeSection-desktop__img-container" style={{ backgroundImage: `url(${props.lengendBackground})`}}>
                    <h1 className="WelcomeSection-desktop__join-title"> Learn how we protect your information</h1>
                </div>
            )
            break;

        

        default:
            bannerCardInformation = (
                <div className="WelcomeSection-desktop__img-container" style={{ backgroundImage: `url(${props.lengendBackground})`}}>
                    <h1 className="WelcomeSection-mobile__join-title" style={{backgroundColor : 'white', padding: '.6em'}}>Something went wrong!</h1>
                </div>
            )
            break;

    }


  return (
    <>
    {bannerCardInformation}
    </>
     
   
  );
}



const CardInfo = ({ props }) => {

    const history = useHistory();


    let cardBodyText;
    switch (window.location.pathname) {
        case '/':
            if (props.cardBannerPosition === 1) {
                cardBodyText = (
                    <p>
                    To help you quickly communicate with a doctor in
                    an emergency, answer questions, track treatments,
                    or manage your medications, <b>we created an easy-
                    to-use virtual primary care experience.</b>
                        {/* <span className="CardInfo__important-text"> &nbsp;It's just a phone call away!</span> */}
                    </p>
                )
            }
            else if (props.cardBannerPosition === 2) {
                cardBodyText = (
                    <p>
                    To help you quickly communicate with a doctor in
                    an emergency, answer questions, track treatments,
                    or manage your medications, <b>we created an easy-
                    to-use virtual primary care experience.</b>
                        {/* <span className="CardInfo__important-text"> &nbsp;It's just a phone call away!</span> */}
                    </p>
                )
            }
            else {
                cardBodyText = (
                    <p>
                        Save time and money with our exclusive telehealth
                        plans. Get care whenever and wherever you need it, &nbsp;
                        <span className="CardInfo__important-text">just a phone call away!</span>
                    </p>
                )
            }
            break;

        case '/price':

            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">Available 24/7, anytime, anywhere.</span> &nbsp;Covers over
                    85% of common conditions typically seen in the
                    emergency department.
                </p>
            )

            break;

        case '/contact-us':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">We provide specialized CARE whenever you need it</span>&nbsp;
                    with our national network of medical
                    professionals and advanced technology application.
                </p>
            )
            break;

        case '/cancel-service':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">Cancel the service quickly and easily.</span>&nbsp;By selecting
                    your cancellation reason, we hope you will help us
                    improve our service.
                </p>
            )

            break;

        case '/terms-of-service':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">Please feel free to contact us</span> if you have any
                    questions or would like more information.
                </p>
            )
            break;

        case '/privacy-policy':
            cardBodyText = (
                < p>
                    <span className="CardInfo__important-text">At Doc Chat, your privacy is important to us.</span> If you
                    have any questions or concerns, please contact us
                    for assistance.
                </p>
            )
            break;
        case '/refund-policy':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">We would be grateful if you could let us know
                        what went wrong and how we can improve.</span> If you
                    wish to request a refund, please proceed as follows
                </p>
            )
            break;
        case '/about-us':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">Learn about the benefits and meet our team of
                        professionals.</span> Become a part of our community of
                    members who are enjoying our world-class medical
                    care services.
                </p>
            )
            break;
        case '/checkout':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">Now you have access to an exclusive family care</span>&nbsp;
                    plan with 24/7 medical consultations, prescription
                    drugs, personalized treatments and more.
                </p>
            )
            break;
        default:
            cardBodyText = null;
            break;
    }


    const goToHandle = () => {

        if (window.location.pathname === '/' || window.location.pathname === '/about-us' || window.location.pathname === '/price' ||
            window.location.pathname === '/privacy-policy' ||
            window.location.pathname === '/reimbursement-guidelines' ||
            window.location.pathname === '/terms-of-service'
        ) {
            history.push('/contact-us')
        }
    }
    return (

        <>
            <div className="WelcomeSection__main-card-info-container">

                <div className="WelcomeSection__main-title-container">
                    <h1 className="text-left">{props.cardTitle}</h1>
                </div>
                <div className="WelcomeSection__main-body-container">
                    <div>
                        {cardBodyText}
                    </div>
                </div>
                {
                    props.cardButtonText.trim() !== "" && (
                        <div className="WelcomeSection__main-button-container">
                            <a href="" onClick={goToHandle} className="WelcomeSection__button">
                            <div className="WelcomeSection__inner-button-container">
                                {props.cardButtonText}
                                </div>
                            </a>
                        </div>
                    ) 
                }
            </div>
        </>

    )
}


export default WelcomeSection;