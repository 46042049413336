import React, { useEffect, useState } from "react";
import './ProductDescription.css';

const ProductDescription = ({ props }) => {

    const [componentToShow, setComponentToShow] = useState(window.innerWidth <= 767.98 ? 'mobile' : window.innerWidth >= 768 && window.innerWidth <= 991.98 ? 'tablet' : 'main');



    useEffect(() => {

        const handleResize = () => {
            const windowWidth = window.innerWidth;
            console.log(windowWidth)
            if (windowWidth <= 767.98) {
                setComponentToShow('mobile');
            } else if (windowWidth >= 768 && windowWidth <= 991.98) {
                setComponentToShow('tablet');
            }
            else {
                setComponentToShow('main');
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });


    const renderComponent = () => {
        switch (componentToShow) {
            case 'mobile':
                return (
                    <div className="ProductDescription__img-inner-container">
                        <img src={props.mobileImg} alt={props.title} className="ProductDescription__img" />
                    </div>
                );
            case 'tablet':
                return (
                    <div className="ProductDescription__img-inner-container">
                        <img src={props.tabletImg} alt={props.title} className="ProductDescription__img" />
                    </div>
                );
            case 'main':
            default:
                return (
                    <div className="ProductDescription__img-inner-container">
                        <img src={props.img} alt={props.title} className="ProductDescription__img" />
                    </div>
                );
        }
    };


    const { paragraphs, instructions } = props;


    let paragraphContent;

    switch (window.location.pathname) {
        case '/':
            paragraphContent = (
                <>
                    <p>
                        <b>
                            Doc chat is a monthly healthcare subscription designed to
                            save you time and money.
                        </b>
                    </p>
                    <br />
                    <p>
                        These plans provide virtual medical care 24 hours a day, 7
                        days a week, 365 days a year, covering several areas of your
                        health in a personalized way.
                    </p>
                    <br />
                    <p>
                        Get the fastest access to medical care for your family in as few
                        as <b>4 simple steps:</b>
                    </p>
                    <br />
                    <ol>
                        <li>All you need to do is download the app or go directly to
                            your health portal on the web and enter your <b>member ID
                                and password.
                            </b>
                        </li>
                        <li>
                            Select the specialty area you need. If you are unable to
                            determine the type of care you need, you may request &nbsp;
                            <b>assistance from our General Care area,</b>&nbsp;who will refer you
                            to a specialist.
                        </li>
                        <li>
                            After choosing your specialty, simply select the physician
                            of your choice and <b>start your virtual visit</b>
                        </li>
                        <li>
                            Get an immediate diagnosis, medical prescriptions,
                            treatment plans, and <b>personal follow-up care.</b>
                        </li>
                    </ol>
                </>
            );
            break;

        case '/about-us':
            paragraphContent = (
                <>
                    <p>
                        <b>
                            Doc Chat LLC is an authorized reseller of the services of
                            GetLyric, Inc.
                        </b>
                    </p>
                    <br />
                    <p>
                        The company provides services related to online medical
                        consultations through video calls, provides storage of
                        electronic medical records and many other services.
                    </p>
                    <br />
                    <p>
                        To access your membership login or go to <strong>To access your membership login or go to</strong>&nbsp;
                        and enter your Member ID. Make your family's healthcare
                        experience smarter and more enjoyable.
                    </p>
                    <br />

                    <p>
                        <b>Download our app, available for Apple or Android.</b>&nbsp;
                        Communicate with a doctor, ask quick questions, or manage
                        your medications with our AI-powered mobile app.
                    </p>

                </>
            );
            break;

        default:
            paragraphContent = null;

    }

    return (
        <section className="ProductDescription__main-container">
            <div className="ProductDescription__explanation-container">
                <div className="ProductDescription__title-container">
                    <h1>{props.title}</h1>
                </div>
                <div className="ProductDescription__explanation-container">
                    {paragraphContent}
                </div>
            </div>

            <div className="ProductDescription__img-container">
                {/* <div className="ProductDescription__img-inner-container">
                    <img src={props.img} alt={props.title} className="ProductDescription__img" />
                </div> */}
                {renderComponent()}
            </div>
        </section>
    );


};

export default ProductDescription;